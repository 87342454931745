import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import THEME from '../theme';
import Flex from './styles/Flex';
import Banner from '../assets/banner.svg';
import MenuIcon from '../assets/icon-menu.svg';
import CloseIcon from '../assets/icon-close.svg';
import mediaqueries from './mediaqueries';

const Nav = styled.div`
  background-color: ${THEME.palette.white};
  text-align: center;
  padding: 0;
`;

const NavSection = styled.div`
  display: flex;

  &.left {
    display: none;

    ${mediaqueries.md`
      flex-basis: 25%;
      display: flex;
    `}
  }

  &.center {
    justify-content: center;
    display: flex;
    flex-basis: 100%;
    padding-top: 20px;

    svg {
      height: 100%;
      width: 250px;
      top: 11px;
      z-index: 1;
      position: relative;
    }

    ${mediaqueries.sm`
      width: 300px;
      height: 100%;
      top: 12px;
    `}

    ${mediaqueries.md`
      flex-basis: 50%;
      svg {
        width: 500px;
        top: 19px;
      }
    `};
  }

  &.right {
    display: none;
    ${mediaqueries.md`
      display: flex;
      justify-content: flex-end;
      flex-basis: 25%;
    `};
  }
`;

const NavLink = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  padding: 0px 20px;
`;

function scrollSmoothly(e, target) {
  if (typeof window !== 'undefined') {
    if (window.location.pathname === '/') {
      e.preventDefault();
      document.querySelector(target).scrollIntoView({ behavior: 'smooth' });
    }
  }
}

const StyledMobileMenu = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: left 0.3s ease;
  top: 0;
  left: ${props => (props.isOpen ? '0' : '100%')};
  right: 0;
  bottom: 0;
  overflow: hidden;

  a {
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    color: hsla(3, 29%, 88%, 1);
  }
`;

const CloseButton = styled.div`
  /* font-size: 30px; */
  font-weight: bold;
  color: hsla(3, 29%, 88%, 1);
  position: absolute;
  line-height: 1;
  padding: 10px;
  top: 0;
  right: 0;

  svg {
    width: 50px;
    fill: hsla(3, 29%, 88%, 1);
  }
`;

const Hamburger = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  display: inline;

  svg {
    width: 30px;
  }

  ${mediaqueries.md`
    display: none;
  `}
`;

const MobileMenu = ({ isActive = true, handleClose }) => (
  <StyledMobileMenu isOpen={isActive}>
    <CloseButton onClick={handleClose}>
      <CloseIcon />
    </CloseButton>
    <Link activeStyle={{ textDecoration: 'underline' }} to="/">
      <span>Home</span>
    </Link>
    <Link
      activeStyle={{ textDecoration: 'underline' }}
      to="/#about-us"
      onClick={e => {
        scrollSmoothly(e, '#about-us');
        handleClose();
      }}
    >
      <span>About us</span>
    </Link>
    <Link
      activeStyle={{ textDecoration: 'underline' }}
      to="/#services"
      onClick={e => {
        scrollSmoothly(e, '#services');
        handleClose();
      }}
    >
      <span>Services</span>
    </Link>
    <Link activeStyle={{ textDecoration: 'underline' }} to="/about">
      <span>Our Team</span>
    </Link>
    <Link activeStyle={{ textDecoration: 'underline' }} to="/contact">
      <span>Contact Us</span>
    </Link>
  </StyledMobileMenu>
);

const Header = () => {
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

  return (
    <header>
      <Hamburger onClick={e => setIsMobileMenuActive(true)}>
        <MenuIcon />
      </Hamburger>
      <MobileMenu
        handleClose={e => setIsMobileMenuActive(false)}
        isActive={isMobileMenuActive}
      />
      <Nav style={{ maxWidth: '80%', margin: '0 auto' }}>
        <Flex>
          <NavSection className="left">
            <NavLink>
              <Link
                activeStyle={{ textDecoration: 'underline' }}
                to="/#about-us"
                onClick={e => scrollSmoothly(e, '#about-us')}
              >
                <span>About us</span>
              </Link>
            </NavLink>
            <NavLink>
              <Link
                activeStyle={{ textDecoration: 'underline' }}
                to="/#services"
                onClick={e => scrollSmoothly(e, '#services')}
              >
                <span>Services</span>
              </Link>
            </NavLink>
          </NavSection>
          <NavSection className="center" flexBasis="50%">
            <Link style={{ display: 'flex' }} to="/">
              <Banner />
            </Link>
          </NavSection>
          <NavSection className="right">
            <NavLink>
              <Link activeStyle={{ textDecoration: 'underline' }} to="/about">
                <span>Our Team</span>
              </Link>
            </NavLink>
            <NavLink>
              <Link activeStyle={{ textDecoration: 'underline' }} to="/contact">
                <span>Contact Us</span>
              </Link>
            </NavLink>
          </NavSection>
        </Flex>
      </Nav>
    </header>
  );
};

export default Header;
