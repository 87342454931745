import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import theme from '../theme';
import mediaqueries from './mediaqueries';

export const SiteThemeContext = React.createContext();
export class SiteThemeProvider extends React.Component {
  state = theme;

  handleThemeChange = ({ e, key }) => {
    this.setState({
      ...theme,
      overrides: {
        ...theme.overrides,
        [key]: e.target.value,
      },
    });
  };

  render() {
    const { children } = this.props;
    return (
      <SiteThemeContext.Provider
        value={{
          ...this.state,
          handleThemeChange: this.handleThemeChange,
        }}
      >
        {children}
      </SiteThemeContext.Provider>
    );
  }
}

const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }


  html {
    box-sizing: border-box;
    font-family: ${props => props.theme.typography.fontFamily};
    color: ${props => props.theme.palette.grey['700']};;

  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    background-color: ${props =>
      props.theme.overrides.backgroundColor || props.theme.backgroundColor};
    margin: 0;
    line-height: 2;
    font-family: ${props => props.theme.typography.fontFamily};
  }

  input, textarea {
    font-family: ${props => props.theme.typography.fontFamily};
    color: ${props => props.theme.palette.grey['700']};
  }

  input[type="color"] {
    height: 50px;
    width: 50px;
    border: 0;
    background: 0;
    padding: 0;
    margin: 0;
  }

  a {
    color: ${props => props.theme.palette.grey['700']};
    text-decoration: none;
    transition: 0.5s all ease;
  }
  a:hover {
    color: ${props => props.theme.palette.grey['900']};
  }
`;

const Inner = styled.div`
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0 auto;

  ${mediaqueries.lg`
    max-width: ${props => `${props.theme.maxWidth}px`};
  `}
`;

const Page = props => (
  <SiteThemeProvider>
    <SiteThemeContext.Consumer>
      {/* eslint-disable-next-line */}
      {({ handleThemeChange, ...siteTheme }) => (
        <ThemeProvider theme={siteTheme}>
          <>
            <GlobalStyle />
            <Inner>{props.children}</Inner>
          </>
        </ThemeProvider>
      )}
    </SiteThemeContext.Consumer>
  </SiteThemeProvider>
);

export default Page;
