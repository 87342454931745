import styled from 'styled-components';
import mediaqueries from '../mediaqueries';

const Spacer = styled.div`
  height: ${props => `${props.size}px`};
  width: 100%;
  background: transparent;

  ${props =>
    props.md &&
    mediaqueries.md`
    height: ${`${props.md}px`}
`}
`;

export default Spacer;
